import Swiper from 'swiper'

window.addEventListener('load', function() {
  new Swiper('.swiper-container', {
    autoplay: {
      delay: 5000
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination'
    }
  })
})
